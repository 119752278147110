import React, {useState} from "react";
import {
    Box,
    Button,
    Dialog,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import CreateUpdateModal from "./createUpdateModal";
import {
    useCreateChargeV2Mutation,
    useDeleteChargeV2Mutation,
    useGetChargesTypesV2Query,
    useUpdateChargeV2Mutation
} from "../../../core/api/charge-api";
import {useParams} from "react-router-dom";

const ChargeManagement = (props) => {
    const [charge, setCharge] = useState();
    const [createMode, setCreateMode] = useState(false);
    const [updateMode, setUpdateMode] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const [createCharge] = useCreateChargeV2Mutation();
    const [updateCharge] = useUpdateChargeV2Mutation();
    const [deleteCharge] = useDeleteChargeV2Mutation();


    const params = useParams();

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetChargesTypesV2Query(params.game)

    const handleCreateCharge = () => {
        setCharge({
            type: "",
            category: "",
            key: "",
            title: "",
            consumable: "",
            customFields: []
        })
        setCreateMode(true);
    };

    const handleCancel = () => {
        setCreateMode(false)
        setUpdateMode(false)
        setDeleteMode(false)
    }

    const handleChargeAction = () => {
        if (createMode) {
            createCharge({data: charge, game: params.game})
        }
        if (updateMode) {
            updateCharge({
                data: {
                    category: charge.category,
                    chargeType: charge.chargeType,
                    consumable: charge.consumable,
                    customFields: charge.customFields,
                    id: charge.id,
                    key: charge.key,
                    title: charge.title,
                    type: charge.type
                },
                game: params.game
            })
        }
        handleCancel();
    }

    const handleEditCharge = (item) => {
        setCharge(item)
        setUpdateMode(true);
    };

    const handleDeleteCharge = (index) => {
        setCharge(data[index])
        setDeleteMode(true);
    };

    const handleDeleteAction = () => {
        deleteCharge({id: charge.id, game: params.game});
        handleCancel();
    }

    return (
        <>
            <h1>Charge Management</h1>

            <Button variant="contained"
                    onClick={() => handleCreateCharge()}
            >
                Add Charge
            </Button>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="charge table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Type</TableCell>
                            <TableCell align="center">Charge Type</TableCell>
                            <TableCell align="center">Category</TableCell>
                            <TableCell align="center">Key</TableCell>
                            <TableCell align="center">Title</TableCell>
                            <TableCell align="center">Consumable</TableCell>
                            <TableCell align="center">Custom Fields</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isSuccess && data.map((item, index) => (
                            <TableRow key={item.id}>
                                <TableCell align="center">{item.type}</TableCell>
                                <TableCell align="center">{item.chargeType}</TableCell>
                                <TableCell align="center">{item.category}</TableCell>
                                <TableCell align="center">{item.key}</TableCell>
                                <TableCell align="center">{item.title}</TableCell>
                                <TableCell align="center">{item.consumable ? "Yes" : "No"}</TableCell>
                                <TableCell align="center">{item.customFieldsDisplayName}</TableCell>
                                <TableCell>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Button
                                                onClick={() => handleEditCharge(item)}
                                            >
                                                Edit
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                onClick={() => handleDeleteCharge(index)}
                                            >
                                                Delete
                                            </Button>
                                        </Grid>
                                    </Grid>


                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={createMode || updateMode} onClose={handleCancel}>
                <CreateUpdateModal
                    handleCancel={handleCancel}
                    handleChargeAction={handleChargeAction}
                    setCharge={setCharge}
                    charge={charge}
                >

                </CreateUpdateModal>
            </Dialog>
            <Dialog
                open={deleteMode}
                onClose={handleCancel}>
                <Box sx={{margin: 5}}>
                    <h2>Are you sure you want to delete charge {charge?.type}?</h2>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item sm={3}>
                            <Button variant="contained"
                                    onClick={handleDeleteAction}
                            >
                                Delete
                            </Button>
                        </Grid>
                        <Grid item sm={3}>
                            <Button variant="outlined"
                                    onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </>

    )
}

export default ChargeManagement;