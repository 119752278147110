import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import {
    Box,
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import chargeConstants from "../constants";


const CreateUpdateModal = (props) => {

    const addAdditionalFields = () => {
        let newAdditionalFields = [...props.charge.customFields]
        newAdditionalFields.push({
            title: "",
            key: "",
            type: ""
        })
        props.setCharge({...props.charge, customFields: newAdditionalFields})
    }

    const handleAdditionalFieldsChange = (index, e, fieldName) => {
        // let newAdditionalFields = [...props.charge.customFields]
        let newAdditionalFields = JSON.parse(JSON.stringify(props.charge.customFields));
        newAdditionalFields[index][fieldName] = e.value
        props.setCharge({...props.charge, customFields: newAdditionalFields})
    }


    return (
        <>
            <DialogTitle>
                Test
                {props.charge.id ? "Update charge " + props.charge.name : "Create a new charge"}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1} sx={{pt:1}}>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel>Type</InputLabel>
                            <Select
                                value={props.charge.type}
                                onChange={(e) => props.setCharge({
                                    ...props.charge,
                                    "type": e.target.value
                                })}
                            >
                                {Object.keys(chargeConstants.CmChargeTypeOptions).map(key => {
                                    return <MenuItem value={key}>{chargeConstants.CmChargeTypeOptions[key]}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel>Category</InputLabel>
                            <Select
                                value={props.charge.category}
                                onChange={(e) => {
                                    props.setCharge({
                                        ...props.charge,
                                        "category": e.target.value})
                                }}
                            >
                                {props.charge.type === chargeConstants.CmChargeTypeOptions.items.toLowerCase() ? chargeConstants.CmChargeConsumableCategoryName.map(option => {
                                    return <MenuItem value={option}>{option}</MenuItem>
                                }) : chargeConstants.CmChargeActionCategoryName.map(option => {
                                    return <MenuItem value={option}>{option}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            sx={{m: 0}}
                            // disabled={props.charge.type !== chargeConstants.CmChargeTypeOptions.items.toLowerCase()}
                            label="chargeType"
                            value={props.charge.chargeType}
                            onChange={(e) => props.setCharge({
                                ...props.charge,
                                "chargeType": e.target.value
                            })}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            sx={{m: 0}}
                            // disabled={props.charge.type !== chargeConstants.CmChargeTypeOptions.items.toLowerCase()}
                            label="Key"
                            value={props.charge.key}
                            onChange={(e) => props.setCharge({
                                ...props.charge,
                                "key": e.target.value
                            })}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            sx={{m: 0}}
                            // disabled={props.charge.type !== chargeConstants.CmChargeTypeOptions.items.toLowerCase()}
                            label="Title"
                            value={props.charge.title}
                            onChange={(e) => props.setCharge({
                                ...props.charge,
                                "title": e.target.value
                            })}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        {props.charge.type === chargeConstants.CmChargeTypeOptions.items.toLowerCase() &&
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={props.charge.consumable}
                                        onChange={(e) => props.setCharge({
                                            ...props.charge,
                                            "consumable": e.target.checked
                                        })}
                                        name="Is Consumable"
                                        color="primary"
                                    />
                                }
                                label="Is Consumable"
                            />}
                    </Grid>


                    {props.charge.customFields.map((field, index) => {
                        return (
                            <>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        sx={{m: 0}}
                                        label="Title"
                                        value={field.title}
                                        onChange={(e) => handleAdditionalFieldsChange(index, e.target, "title")}
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        sx={{m: 0}}
                                        label="Key"
                                        value={field.key}
                                        onChange={(e) => handleAdditionalFieldsChange(index, e.target, "key")}
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                        <Select
                                            value={field.type}
                                            onChange={(e) => handleAdditionalFieldsChange(index, e.target, "type")}
                                        >
                                            {chargeConstants.CmChargeAdditionalFieldsTypes.map(option => {
                                                return <MenuItem value={option}>{option}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </>
                        )
                    })}
                    {props.charge.type !== chargeConstants.CmChargeTypeOptions.items.toLowerCase() &&
                        <Box width={'100%'} m={1} mt={2}>
                            <Button
                                variant={'outlined'}
                                onClick={() => addAdditionalFields()}>
                                Add custom fields
                            </Button>
                        </Box>}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained"
                        onClick={props.handleChargeAction}
                >
                    Save
                </Button>

                <Button variant="outlined"
                        onClick={props.handleCancel}
                >
                    Cancel
                </Button>
            </DialogActions>
        </>
    )
}

export default CreateUpdateModal;